
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    // import Error from 'next/error';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
export default function PageNotFound() {
    const router = useRouter();
    const [link, setLink] = useState('');
    useEffect(() => {
        const timer = setTimeout(() => {
            if (router.asPath === '/login' ||
                router.asPath.includes('check-point') ||
                router.asPath.includes('test-level')) {
                void router.push(router.asPath);
            }
            else {
                void router.push('/');
            }
        }, 1000);
        setLink(window.location.href);
        return () => clearTimeout(timer);
    }, [router]);
    return (<div>
      <div id="loader-404">
        <div id="shadow-404"/>
        <div id="box-404"/>
      </div>
      <div className="absolute top-[58%] right-[38%] text-neutral-400 text-sm">{link}</div>
    </div>);
}

    async function __Next_Translate__getStaticProps__195fb467924__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195fb467924__ as getStaticProps }
  